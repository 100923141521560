<script>
import BtnDelete from '../components/form/BtnDelete.vue'
import TagLabel from '../components/TagLabel.vue'
import Btn from '../components/form/Btn.vue'
import TextField from '../components/form/TextField.vue'
import Modal from '../components/Modal.vue'
import Icon from '../components/Icon.vue'
import AvatarIcon from '../components/AvatarIcon.vue'
import TopHeader from '@/components/TopHeader.vue';
import { UtilitiesMixin } from '@/mixins/UtilitiesMixin';
import { ValidateMixin } from '@/mixins/ValidateMixin';

export default {
    name: 'teams',
    mixins: [UtilitiesMixin, ValidateMixin],
    components: {TopHeader, AvatarIcon, Icon, Modal, TextField, Btn, TagLabel, BtnDelete},

    mounted () {
        this.getTeams()
    },

    data() {
        return {
            teams: null,
            currentTeam: null,
            invite: null,
            invites: {
                users: []
            },
            action: null,
            working: {
                update: false,
                invite: false,
            },
            error: {
                invite: null
            }
        }
    },

    methods: { 

        inviteUser(){
            if(!this.isEmail(this.invite)){
                this.error.invite = "E-mail inválido."
                return
            } 
            this.error.invite = null
            this.working.invite = true
            this.$axios
            .post(this.$api + "team/invite/" + this.currentTeam.id, {email: this.invite})
            .then(response => {
                this.addUserPlaceholder(this.invite)
                this.invite = null
                this.working.invite = false
            })
        },

        addUserPlaceholder(email){
            this.invites.users.push({email, name: null})
        },


        getTeams(){
            this.$axios
            .get(this.$api + 'user/' + this.$user.id + '/teams')
            .then(response => {
                this.teams = response.data.data
            })
        },

        getInvites(id){
            this.$axios
            .get(this.$api + 'team/invites/' + id)
            .then(response => {
                this.invites.users = response.data.data
            })
        },
        
        getTeam(id){
            this.$axios
            .get(this.$api + 'team/' + id)
            .then(response => {
                this.currentTeam = response.data.data
            })
        },

        updateTeam(id){
            this.working.update = true
            this.$axios
            .put(this.$api + 'team/' + id, this.currentTeam)
            .then(response => {
                this.getTeams()
                this.working.update = false
            })
        },

        deleteTeam(id){
            this.$axios
            .delete(this.$api + 'team/' + id)
            .then(response => {
                this.currentTeam = null
                this.getTeams()
            })
        },

        editTeam(id){
            this.action = "update"
            this.getTeam(id)
            this.getInvites(id)
        },

        modalClose(){
            this.currentTeam = null
        },

        createTeam(){
            this.action = "create"            
            this.currentTeam = {
                name: null,
                description: null
            }
        },

        storeTeam(){
            this.$axios
            .post(this.$api + 'team', this.currentTeam)
            .then(response => {
                this.getTeams()
                this.action = "update"
                this.currentTeam = response.data.data
            })

        },

        removeUser(userId, index){
            this.$axios
            .put(this.$api + 'team/remove/' + this.currentTeam.id, {user_id: userId})
            .then(response => {
                this.getTeams()
                this.getTeam(this.currentTeam.id)
            })

        }
        
    },
    



}
</script>

<template>
    <div class="page-settings">

        <TopHeader />

        <!-- Dashboard -->
        <main v-if="$user.limits.feature.create_team" class="container main-content mt64">
            <div class="page-header">
                <h1 class="page-title title-xl mb8">Times</h1>
                <p class="page-description">Trabalhar com amigos é sempre melhor! :)</p>
            </div>            

            <section class="my-teams mt64">
                <h2 class="h2">Seus times</h2>
                <ul class="teams-list mt32">
                    <li v-for="team in teams" :key="team.id" @click="editTeam(team.id)" :style="{borderLeftColor: team.color}" class="team-item">
                        <div class="team-name">{{team.name}}</div>
                        <div class="team-description mt8">{{team.description}}</div>
                        <div class="team-members">
                            <div v-for="user in team.users.slice(0,5)" :key="user.email" class="avatar-item">
                                <AvatarIcon :color="user.color" class="member-avatar" :name="user.name" />
                            </div>
                            <div v-if="team.users.length > 5" class="plus-icon">+{{team.users.length - 5}}</div>
                        </div>
                    </li>
                    <li @click="createTeam" class="team-item add-new">
                        <Icon class="icon" name="plus" />
                        <span class="label">Criar um time</span>
                    </li>

                </ul>
            </section>

            <Modal class="modal-edit" :active="currentTeam != null" @modal-close="modalClose">
                <div v-if="currentTeam" class="team-data">
                    <TextField v-model="currentTeam.name" size="lg" layout="ghost" :data="{}" placeholder="Nome do time" />  
                    <TextField v-model="currentTeam.description" size="sm" layout="ghost" :data="{}" class="" placeholder="Descrição do time" />  
                    
                    <div class="actions mt16">
                        <Btn v-if="action == 'create'" @click.native="storeTeam()" size="xs">Criar time</Btn>
                        <Btn v-else @click.native="updateTeam(currentTeam.id)" :loading="working.update" size="xs">Atualizar</Btn>
                        <BtnDelete v-if="action != 'create'" @delete="deleteTeam(currentTeam.id)" size="xs" class="ml16">Excluir</BtnDelete>
                    </div>

                    <div v-if="currentTeam.users" class="members-sectio">
                        <h3 class="h3 mt32">Membros</h3>
                        <ul @keyup.enter="inviteUser" class="team-users-list mt8">
                            <li v-for="(user, index) in currentTeam.users" :key="user.id" class="team-member mb8">
                                <AvatarIcon size="sm" :key="user.id" class="member-avatar" :name="user.name" />
                                <span class="member-name ml8">{{user.name}}</span>
                                <div v-if="index > 0" class="actions"><Btn @click.native="removeUser(user.id, index)" size="sm" layout="remove"></Btn></div>
                            </li>
                            <li v-for="user in invites.users" :key="user.email" class="team-member mb8">
                                <AvatarIcon size="sm" :key="user.email" class="member-avatar" :name="user.email" />
                                <span class="member-name ml8">{{user.email}}</span>
                                <TagLabel class="ml8" type="beta">Pendente</TagLabel>
                            </li>
                        </ul>
                        <div class="team-member add-new-member">
                            <TextField v-model="invite" type="email" size="sm" layout="line" :data="{}" placeholder="Insira um e-mail para convidar" :error="error.invite" class="invite-field"/>  
                            <Btn @clicked="inviteUser" :loading="working.invite" class="btn-invite ml16" type="secondary" size="xs"><Icon name="paper-plane" class="mr8"/> Convidar</Btn>
                        </div>
                    </div>
                </div>
            </Modal>
    
        </main>

        <section v-else class="upgrade-block">
            <div class="upgrade-block-content">
                <img class="upgrade-image mb32" src="@/assets/img/teams-art.png" alt="Ilustração de pessoas">
                <h1 class="h1 mb8">Traga o seu time! <TagLabel type="beta" class="ml4">Beta</TagLabel></h1>
                <h2 class="h2 mb32">Trabalhar sozinho é chato. Crie times, adicione colegas, compartilhe acesso a formulários.</h2>
                <Btn :link="{name: 'userSettings'}" class="upgrade-button">Atualizar para o plano Empresas</Btn>
            </div>
        </section>

    </div>
</template>


<style lang="sass">

.main-content
    padding-bottom: 64px

.upgrade-block
    display: flex
    align-items: center
    justify-content: center
    text-align: center

    .upgrade-block-content
        width: 90%
        max-width: 440px

    .h1
        font-size: $fzLg
        display: flex
        align-items: center
        justify-content: center

    .h2
        font-weight: normal
        font-size: $fzSm
        line-height: 120%

    .upgrade-image
        max-width: 340px

.teams-list
    display: flex
    flex-wrap: wrap

    .team-item
        padding: $mgSm
        border: 1px solid $cG2
        border-radius: 4px
        display: flex
        flex-direction: column
        width: 200px
        height: 150px
        margin-right: $mgSm      
        margin-bottom: $mgSm      
        box-shadow: 0 0 4px rgba(0,0,0,.15)  
        border-left: $cPrimary 6px solid

        &:hover
            box-shadow: 0 0 30px rgba(0,0,0,.15)
            transition: .3s
            cursor: pointer
        

        .team-name
            font-weight: bold
            font-size: $fzSm

        .team-description
            font-size: $fzXs
            color: $cG5
            line-height: 120%

        .team-forms
            font-size: $fzSm
            color: $cG5
        

        .team-members
            display: flex
            margin-top: auto

            .member-avatar
                outline: 2px solid $cWhite
                margin-left: -5px

        .plus-icon
            outline: 2px solid $cWhite
            margin-left: -5px
            width: 30px
            height: 30px
            border-radius: 30px
            background: $cWhite
            display: flex
            align-items: center
            justify-content: center

    
        &.add-new
            align-items: center
            justify-content: center
            color: $cG3
            border: 0

            .icon
                font-size: $fzXl
                margin-bottom: $mgXs

            &:hover
                color: $cPrimary

.modal-edit
    .h3
        font-weight: normal
        font-size: $fzXs

    .actions
        display: flex

    .team-users-list
        max-height: 300px
        overflow: auto

        .team-member
            display: flex
            align-items: center

            .member-name
                font-size: $fzXs

            .actions
                margin-left: auto
        
    .add-new-member
        display: flex
        align-items: center
        
        .label
            width: 230px
            font-size: $fzXs    

        .invite-field
            width: 250px







</style>